import React from "react";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <footer className="bg-dark text-white pt-4 px-4 pb-0">
      <div className="container py-4">
        <div className="row">
          {/* Left section for DEVOSKILLS details */}
          <div className="col-md-6 text-left">
            <h5 alt='devoskills'>DEVOSKILLS</h5>
            <p>
              6 AV DE VERSAILLES 78220 VIROFLAY
              <br />
              <span onclick="window.open('tel:+33668677406');">Tel: +33668677406</span> 
              <br />
              <span onclick="window.open('email:contact@devoskills.com');"></span>  Email: contact@devoskills.com
            </p>
          </div>

          {/* Right section for social media icons and copyright */}
          <div className="col-md-6 text-right">
            <h5>Suivez-nous</h5>
            <div className="mt-4 social-icons">
              <a
                alt='devoskills'
                href="https://www.facebook.com/Devoskills-100663005389588"
                className="text-white"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="2x"
                  className="icon"
                />
              </a>
              <a alt='devoskills' href="https://twitter.com/Devoskills1" className="text-white">
                <FontAwesomeIcon icon={faTwitter} size="2x" className="icon" />
              </a>
              <a
                alt='devoskills'
                href="https://www.linkedin.com/company/devoskills/"
                className="text-white"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size="2x"
                  className="icon"
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        {/* Copyright */}
        <div className="text-center p-3">
          © {new Date().getFullYear()}{" "}
          <a alt='devoskills' className="text-white" href="https://devoskills.com/">
            DEVOSKILLS
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
