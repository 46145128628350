import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
function Contact() {
  const formik = useFormik({
    initialValues: {
      name: "",
      function: "",
      email: "",
      phone: "",
      message: "",
    },
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = "Ce champ est requis";
      }
      if (!values.function) {
        errors.function = "Ce champ est requis";
      }
      if (!values.email) {
        errors.email = "Ce champ est requis";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Adresse email invalide";
      }
      if (!values.phone) {
        errors.phone = "Ce champ est requis";
      } 
      if (!values.message) {
        errors.message = "Ce champ est requis";
      }
      return errors;
    },
    validateOnChange: true,
    onSubmit: values => {
        console.log(values)
        handleSubmit();
    },
  });


 
  const handleSubmit = () => {
    console.log(formik.values)
    emailjs.send('service_doctnjl', 'template_a7648ii', formik.values, 'h7QknaJzfwlKEWCCW')
      .then(function (response) {
        toast.success("Votre message a été envoyé avec succès !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
        });
        formik.resetForm();
      }, function (err) {
        console.log('FAILED...', err);
      });
    
    
  };



  return (
    <div className="contact py-5" id="contact">
      <div className="container">
        <div className="row contact_box align-items-center">
          <div className="col-lg-6 col-sm-12 p-0">
            <img
              src="../images/contact.png"
              style={{ maxWidth: "100%" }}
              alt="Contact"
            />
          </div>
          <div className="col-lg-6 col-sm-12 py-3 ps-5 pe-4">
            <h2 className="mb-3">Contactez-nous</h2>
            <Form onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="formName" className="form-group">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Ex: Jean Dupont"
                      onChange={formik.handleChange}
                      value={formik.values.name} />
                    {formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formFunction" className="form-group">
                    <Form.Label>Fonction</Form.Label>
                    <Form.Control
                      type="text"
                      name="function"
                      placeholder="Ex: Développeur"
                      onChange={formik.handleChange}
                      value={formik.values.function} />
                    {formik.errors.function ? <div className="error">{formik.errors.function}</div> : null}
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="formEmail" className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Ex: ********@gmail.com"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}                    
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formPhone" className="form-group">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                      name="phone"
                      type="text"
                      placeholder="Ex: +33 00 00 00 00"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null}
                  </Form.Group>
                </div>
              </div>

              <Form.Group controlId="formMessage" className="form-group">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  placeholder="Ex: Bonjour, je suis intéressé par vos services..."
                />
                {formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
              </Form.Group>

              <div className="contact_btn">
                <Button
                  disabled={!formik.isValid}
                  variant="primary"
                  type="submit"
                  className="mt-3 justify-content-end"
                >
                  Envoyer
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
