import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import CustomNavbar from "./components/CustomNavbar";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Showcase from "./components/Showcase";
import About from "./components/About";
import Contact from "./components/Contact";
import { ToastContainer,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
function App() {
  return (
    <>
      <ToastContainer />
      <Header />
      <CustomNavbar />
      <Showcase />
      <About />
      <Services />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
