import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-scroll";

function Navigation() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>
          <Link
            to="home"
            spy={true}
            smooth={true}
            duration={500}
            className="nav-link cursor-pointer d-flex align-items-center"
          >
            <img
              src={"/images/logo-devskills.svg"}
              alt="logo"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
            <span className="font-weight-bold ms-3 logo-text">DEVOSKILLS</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer"
            >
              à propos
            </Link>
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer"
            >
              Services
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer"
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
