import React from "react";
import { Link } from "react-scroll";

function Showcase() {
  return (
    <div className="slider-home py-5 d-flex align-items-center" id="showcase">
      <div className="container">
        <div className="showcase">
          <div className="single_slider">
            <div className="slider_content">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                <div className="slider_text">
                <div className="slider_text_inner">
                  <h5>DEVOSKILLS</h5>
                  <h1>Agence IT et Business la plus fiable.</h1>
                </div>
                <div className="slider_text_desc pt-4">
                  <p>
                    DEVOSKILLS est une agence IT et Business qui accompagne les
                    entreprises dans la mise en place de solutions digitales et
                    de stratégies marketing pour booster leur croissance. Nous
                    sommes une équipe de passionnés qui mettons notre expertise
                    au service de nos clients pour les aider à atteindre leurs
                    objectifs. Nous sommes spécialisés dans le développement
                    d’applications web et mobiles, le développement de sites
                    web, le marketing digital, le design graphique et la
                    formation. Nous sommes basés en France, à Paris et à
                    Viroflay.
                  </p>
                </div>
                <div className="slider_button pt-5 d-flex">
                  <div className="button">
                    <Link to="/">
                      Contactez-nous<i className="fa fa-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  );
}

export default Showcase;
