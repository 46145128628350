import React from "react";

function About() {
  return (
     
        <div id="about" className="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
          <div class="section_title text_left mb-40 mt-3">
            <div class="section_sub_title uppercase mb-3">
              <h6>À PROPOS DE DEVOSKILLS</h6>
            </div>
            <div class="section_main_title">
              <h2>Agence IT et Business la plus <span>fiable.</span></h2>
              
            </div>
            <div class="em_bar">
              <div class="em_bar_bg"></div>
            </div>
            <div class="section_content_text pt-4">
              <p>
              DEVOSKILLS est une agence IT et Business qui accompagne les
            entreprises dans la mise en place de solutions digitales et de
            stratégies marketing pour booster leur croissance. Nous sommes une
            équipe de passionnés qui mettons notre expertise au service de nos
            clients pour les aider à atteindre leurs objectifs. Nous sommes
            spécialisés dans le développement d’applications web et mobiles, le
            développement de sites web, le marketing digital, le design
            graphique et la formation. Nous sommes basés en France, à Paris et à
            Viroflay.
              </p>
            </div>
          </div>
         
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
        <div className="img-about">
                  <img src="../images/about.png" alt="placeholder" />
              </div> 
              <div class="border_ift"></div>
        </div>
        </div>
        </div>
        {/* <div>
                  <img src="../images/about.png" alt="placeholder" />
              </div> */}
      </div>
     
  );
}

export default About;
