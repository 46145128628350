import React from "react";

function Services() {
  return (
    <div id="services" className="services py-5">
      <div className="container">
        
        <div class="section_title text_center white mb-5">
          
          <div class="section_main_title">
            <h2 className="mb-3">NOS SERVICES</h2>
          </div>
          <div class="section_sub_title text-uppercase mb-3">
            <h6>Concevez le Concept de Votre Idée d'Entreprise Dès Maintenant</h6>
          </div>
          <div class="em_bar">
            <div class="em_bar_bg"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
            <div className="service_flipbox mb-3">
              <div className="service_flipbox_font">
                <div className="service_flipbox_inner">
                  <div className="service_flipbox_icon">
                    <div className="icon">
                      <i className="flaticon-padlock"></i>
                    </div>
                  </div>
                  <div className="flipbox_title">
                    <h3>Conception UI/UX</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous concevons des interfaces utilisateur attrayantes...
                    </p>
                  </div>
                </div>
              </div>
              <div className="service_flipbox_back ">
                <div className="service_flipbox_inner">
                  <div className="flipbox_title">
                    <h3>Conception UI/UX</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous concevons des interfaces utilisateur attrayantes et
                      des expériences utilisateur intuitives pour les
                      applications web et mobiles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
            <div className="service_flipbox mb-3">
              <div className="service_flipbox_font">
                <div className="service_flipbox_inner">
                  <div className="service_flipbox_icon">
                    <div className="icon">
                      <i className="flaticon-padlock"></i>
                    </div>
                  </div>
                  <div className="flipbox_title">
                    <h3>Développement Web et Mobile</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous sommes spécialisés dans la création d'applications
                      web...
                    </p>
                  </div>
                </div>
              </div>
              <div className="service_flipbox_back ">
                <div className="service_flipbox_inner">
                  <div className="flipbox_title">
                    <h3>Développement Web et Mobile</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous sommes spécialisés dans la création d'applications
                      web et mobiles personnalisées répondant aux besoins de nos
                      clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
            <div className="service_flipbox mb-3">
              <div className="service_flipbox_font">
                <div className="service_flipbox_inner">
                  <div className="service_flipbox_icon">
                    <div className="icon">
                      <i className="flaticon-padlock"></i>
                    </div>
                  </div>
                  <div className="flipbox_title">
                    <h3>Marketing Digital</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous aidons les entreprises à développer leur présence en
                      ligne...
                    </p>
                  </div>
                </div>
              </div>
              <div className="service_flipbox_back ">
                <div className="service_flipbox_inner">
                  <div className="flipbox_title">
                    <h3>Marketing Digital</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous aidons les entreprises à développer leur présence en
                      ligne et à atteindre leurs clients grâce à des stratégies
                      de marketing digital efficaces.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-6">
            <div className="service_flipbox mb-3">
              <div className="service_flipbox_font">
                <div className="service_flipbox_inner">
                  <div className="service_flipbox_icon">
                    <div className="icon">
                      <i className="flaticon-padlock"></i>
                    </div>
                  </div>
                  <div className="flipbox_title">
                    <h3>Business Intelligence</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous aidons les entreprises à prendre des décisions...
                    </p>
                  </div>
                </div>
              </div>
              <div className="service_flipbox_back ">
                <div className="service_flipbox_inner">
                  <div className="flipbox_title">
                    <h3>Marketing Digital</h3>
                  </div>
                  <div className="flipbox_desc">
                    <p>
                      Nous aidons les entreprises à prendre des décisions
                      éclairées en leur fournissant des informations
                      exploitables basées sur des données.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Services;
