import React from 'react';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  const email = 'contact@devskills.com'; // Your site's email address
  //const [isScrolled, setIsScrolled] = useState(false);
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`; // Open default email client with the specified email address
  };

  return (
    <Navbar expand="lg" className="bg-primary text-white">
      <Container>
        <div className="link-top-header mr-auto">
          <div className="contact-header d-flex align-items-center justify-content-between">
            <div className="contact-info mail-info" onClick={handleEmailClick}>
              <FontAwesomeIcon icon={faEnvelope} className='text-white' />
              <span className="contact-text">{email}</span>
            </div>
            <div className="contact-info phone-info" onclick="window.open('tel:+33668677406');">
              <FontAwesomeIcon icon={faPhone} className='text-white' />
              <span className="contact-text">+33668677406</span>
            </div>
            <div className="contact-info adress-info">
              <FontAwesomeIcon icon={faLocationDot} className='text-white' />
              <span className="contact-text">6 AV DE VERSAILLES 78220 VIROFLAY</span>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
